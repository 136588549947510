import { Button } from '@smwb/summer-ui'
import { AgentumReportWithRelations } from '@api/generated'
import { generatePath, useNavigate, useParams } from 'react-router-dom'
import { Urls } from '@/urls'
import { BaseReportFields } from '@modules/missions/keaz/types'

interface StepsTableActionsCellProps {
  step: AgentumReportWithRelations
}

export function StepsTableActionsCell({ step }: StepsTableActionsCellProps) {
  const { id, taskId, groupIndex } = useParams()

  const navigate = useNavigate()

  return (
    <Button
      size='small'
      onClick={() =>
        navigate(
          generatePath(Urls.KeazStep, {
            id: id || null,
            taskId: taskId || null,
            groupIndex: groupIndex || null,
            stepId: step[BaseReportFields.id] || null
          })
        )
      }
    >
      Перейти
    </Button>
  )
}
