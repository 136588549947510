import baseStyle from '../index.module.less'
import style from './index.module.less'
import { generatePath, useNavigate, useParams } from 'react-router-dom'
import { apiCall } from '@api/resources/apiCall'
import { BaseReportFields, Groups } from '@modules/missions/keaz/types'
import { skipToken } from '@reduxjs/toolkit/query'
import { PageTitle } from '@modules/missions/keaz/components/pageTitle'
import cn from 'classnames'
import { GroupCard } from '@modules/missions/keaz/task/components/groupCard'
import { Urls } from '@/urls'
import { PROJECT } from '@modules/missions/keaz/const'
import { Assignee } from '@modules/missions/keaz/task/components/assignee'

export function Index() {
  const { id, taskId } = useParams()
  const { data: task, isLoading } = apiCall.useAgentumReportControllerFindByIdQuery(
    id ? [id, PROJECT, taskId || ''] : skipToken
  )

  const navigate = useNavigate()

  if (isLoading) {
    return <div>'loading'</div>
  }

  if (!task) {
    return <div>Задача не найдена</div>
  }

  return (
    <>
      <PageTitle
        title={`Задача №${String(task[BaseReportFields.name])}`}
        onBack={() =>
          navigate(generatePath(Urls.Mission, { id: id || null }), {
            replace: true
          })
        }
      />

      <Assignee task={task} className={cn(baseStyle.section, style.assignee)} />

      <div className={cn(baseStyle.section, style.groups)}>
        {Object.values(Groups).map((group, index) => (
          <GroupCard
            key={index}
            task={task}
            group={group}
            onButtonClick={() =>
              navigate(
                generatePath(Urls.KeazGroup, {
                  id: id || null,
                  taskId: task[BaseReportFields.id] || null,
                  groupIndex: index.toString()
                })
              )
            }
          />
        ))}
      </div>
    </>
  )
}
