import { BaseReportFields, GroupStatus, ReportType, StepStatus } from '@modules/missions/keaz/types'
import { AgentumReportWithRelations } from '@api/generated'

export const isCompletedStep = (step: AgentumReportWithRelations) => {
  return isApprovedStep(step) || step[BaseReportFields.status] === StepStatus.signed
}

export const isApprovedStep = (step: AgentumReportWithRelations) => {
  return step[BaseReportFields.status] === StepStatus.approved
}

export const getStepsSummaryStatus = (
  steps: AgentumReportWithRelations[],
  task?: AgentumReportWithRelations
) => {
  /*
   * Правила отображения статусов задач и разделов
   * 1 "Не обследован": Если все шаги в задаче/разделе имеют статус "Не обследован".
   * 2 "В работе": Если хотя бы один шаг в задаче/разделе имеет статус "В работе".
   * 3 "Ожидает проверки": Если хотя бы один шаг в задаче/разделе имеет статус "Ожидает проверки".
   * 4 "Отклонен": Если хотя бы один шаг в задаче/разделе имеет статус "Отклонен".
   * 5 "Принят": Если все шаги в задаче/разделе имеют статус "Принят".
   * 6 (такие статусы пока не реализованы) "Отправлено в ERP...": Если все шаги в задаче имеют статус "Принят" и отчетность отправлена в ERP клиента.
   * 7 (такие статусы пока не реализованы) Дополнительный статус (не прописан в таблице): Если все шаги в задаче имеют статус "Принят" и отчетность отклонена в ERP клиента.
   */

  let hasInProgress = false
  let hasSigned = false
  let hasRejected = false
  let allApproved = true

  steps.forEach((step) => {
    if (step.status === GroupStatus.inProgress) {
      hasInProgress = true
    }
    if (step.status === GroupStatus.signed) {
      hasSigned = true
    }
    if (step.status === GroupStatus.rejected) {
      hasRejected = true
    }
    if (step.status !== GroupStatus.approved) {
      allApproved = false
    }
  })

  let status = GroupStatus.default

  if (hasRejected) {
    status = GroupStatus.rejected
  } else if (hasSigned) {
    status = GroupStatus.signed
  } else if (hasInProgress) {
    status = GroupStatus.inProgress
  } else if (allApproved) {
    if (task && task[BaseReportFields.type] === ReportType.signed) {
      status = GroupStatus.sendedToERP
    } else {
      status = GroupStatus.approved
    }
  }

  return status
}
