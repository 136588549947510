import { useParams } from 'react-router-dom'
import { apiCall } from '@api/resources/apiCall'
import { PROJECT } from '@modules/missions/keaz/const'
import { BaseReportFields, ReportSubtype } from '@modules/missions/keaz/types'
import { skipToken } from '@reduxjs/toolkit/query'
import { isApprovedStep } from '@modules/missions/keaz/utils/steps'
import { AgentumReportWithRelations } from '@api/generated'

interface TasksTableCellApprovedStepsProps {
  task: AgentumReportWithRelations
}

export function TasksTableCellApprovedSteps({ task }: TasksTableCellApprovedStepsProps) {
  const { id } = useParams()
  const { data: steps = [] } = apiCall.useAgentumReportControllerFindQuery(
    id
      ? [
          id,
          PROJECT,
          {
            where: {
              [BaseReportFields.subtype]: ReportSubtype.step,
              [BaseReportFields.taskId]: task[BaseReportFields.origin],
              [BaseReportFields.deleted]: false
            }
          }
        ]
      : skipToken
  )

  const approvedSteps = steps.reduce((count: number, step) => count + +isApprovedStep(step), 0)

  return <>{`${approvedSteps}/${steps.length}`}</>
}
