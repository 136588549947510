import { Icon } from '@/components/ui/base/icon/icon'
import { apiCall } from '@api/resources/apiCall'
import { useParams } from 'react-router-dom'
import { PROJECT } from '@modules/missions/keaz/const'
import { useEffect, useRef } from 'react'
import { ProgressIndicator, useSnackbar } from '@smwb/summer-ui'
import style from './taskReportButton.module.less'
import { AgentumReportWithRelations } from '@api/generated'
import { BaseReportFields } from '@modules/missions/keaz/types'

interface TaskReportButtonProps {
  task: AgentumReportWithRelations
}

export function TaskReportButton({ task }: TaskReportButtonProps) {
  const { id } = useParams()

  const { addSnackbar } = useSnackbar()
  const addSnackbarRef = useRef(addSnackbar)

  const [
    taskReport,
    { data: taskReportData, isLoading: isTaskReportLoading, error: taskReportError }
  ] = apiCall.useKeazReportsControllerTaskMutation()

  const [
    deviationsReport,
    {
      data: deviationsReportData,
      isLoading: isDeviationsReportLoading,
      error: deviationsReportError
    }
  ] = apiCall.useKeazReportsControllerTaskDeviationsMutation()

  useEffect(() => {
    if (!isTaskReportLoading) {
      if (!!taskReportData && !taskReportError) {
        window.open(taskReportData.url, '_blank')
      } else if (taskReportError) {
        addSnackbarRef.current({
          message: 'Ошибка построения отчета по шагам',
          position: 'top-right',
          variant: 'danger'
        })
      }
    }
  }, [isTaskReportLoading, taskReportData, taskReportError])

  useEffect(() => {
    if (!isDeviationsReportLoading) {
      if (!!deviationsReportData && !deviationsReportError) {
        window.open(deviationsReportData.url, '_blank')
      } else if (deviationsReportError) {
        addSnackbarRef.current({
          message: 'Ошибка построения отчета по отклонениям',
          position: 'top-right',
          variant: 'danger'
        })
      }
    }
  }, [isDeviationsReportLoading, deviationsReportData, deviationsReportError])

  if (isTaskReportLoading || isDeviationsReportLoading) {
    return <ProgressIndicator type='indeterminate' variant='circular' className={style.loader} />
  }

  return (
    <div
      onClick={(e) => {
        e.stopPropagation()
        void taskReport([id || '', PROJECT, task[BaseReportFields.origin] as string])
        void deviationsReport([id || '', PROJECT, task[BaseReportFields.origin] as string])
      }}
    >
      <Icon icon='download' className={style.icon} />
    </div>
  )
}
