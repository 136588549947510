import style from './stepsTable.module.less'
import { Asigner, BaseReportFields, StepStatus } from '@modules/missions/keaz/types'
import { InProgressTime } from '@modules/missions/keaz/group/components/inProgressTime'
import { secondsToHHMMSS } from '@modules/missions/keaz/utils/time'
import { AgentumReportWithRelations } from '@api/generated'
import { getStepTitle } from '@modules/missions/keaz/utils/step'
import { ColumnDef } from '@tanstack/react-table'
import { Table } from '@smwb/summer-ui/dist/connects/rt'
import { StepsTableActionsCell } from '@modules/missions/keaz/group/components/stepsTableActionsCell'
import { formatFullName } from '@modules/missions/keaz/components/formatFullName'

interface StepsTableProps {
  steps: AgentumReportWithRelations[]
  isLoading: boolean
}

const getStepStatusColor = (status: StepStatus) => {
  let color = 'black'

  switch (status) {
    case StepStatus.signed:
      color = 'gray2'
      break
    case StepStatus.default:
      color = 'gray5'
      break
    case StepStatus.approved:
      color = 'success'
      break
    case StepStatus.inProgress:
      color = 'warning'
      break
    case StepStatus.rejected:
      color = 'orange'
      break
  }

  return `var(--agm-color-${color})`
}

const columns: ColumnDef<AgentumReportWithRelations>[] = [
  {
    header: 'Название шага',
    cell: ({ row }) => <>{getStepTitle(row.original)}</>
  },
  {
    header: 'Планируемое время выполнения',
    cell: ({ row }) => (
      <>{secondsToHHMMSS((row.original[BaseReportFields.timeNorm] as number[]) || [])}</>
    )
  },
  {
    header: 'Фактическое время выполнения',
    cell: ({ row }) => <>{<InProgressTime step={row.original} />}</>
  },
  {
    header: 'Исполнитель',
    cell: ({ row }) => (
      <>
        {row.original[BaseReportFields.assigner]
          ? formatFullName(
              (row.original[BaseReportFields.assigner] as Asigner).firstname,
              (row.original[BaseReportFields.assigner] as Asigner).lastname
            )
          : ''}
      </>
    )
  },
  {
    header: 'Статус',
    cell: ({ row }) => (
      <span
        style={{
          color: getStepStatusColor(row.original[BaseReportFields.status] as StepStatus)
        }}
      >
        {row.original[BaseReportFields.status]}
      </span>
    )
  },
  {
    header: '',
    accessorKey: 'actions',
    cell: ({ row }) => <StepsTableActionsCell step={row.original} />
  }
]

export function StepsTable({ steps, isLoading }: StepsTableProps) {
  return <Table data={steps} columns={columns} isLoading={isLoading} className={style.table} />
}
